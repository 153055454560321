@use 'styles/palette.module.scss';
@use 'styles/spacing.module.scss';

.wrapper {
  display: flex;
  flex: 1;
}

html,
body {
  height: 100%;

  * {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  }
}

.wrapper-right {
  overflow-x: auto;
  flex-direction: column;
  width: 100%;
}

.main {
  max-width: 99%;
  height: 100%;
  margin-top: 80px;
  background: palette.$defaultBackground;
  margin-right: 0px;
  margin-left: 0px;
  padding: 1rem;
}

mark {
  background: palette.$warningLight;
}

mark::before,
mark::after {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(100%);
}

mark::before {
  content: ' [highlight start] ';
}

mark::after {
  content: ' [highlight end] ';
}
