.default-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__content {
    width: 420px;
    text-align: center;
  }
}
