@use 'styles/spacing.module.scss';

.inline-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: --spacing(2) 0;

  &--left {
    justify-content: left;
  }
}
