@use 'styles/palette.module.scss';
@use 'styles/spacing.module.scss';

.api-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__content {
    width: 550px;
    text-align: center;
  }

  &__message {
    font-size: spacing.$base * 2;
  }

  &__reload-button {
    color: palette.$errorDark;
    font-size: spacing.$base * 2;
    border: 1px solid palette.$errorMain;
  }
}
