// See https://www.figma.com/file/f7vNdOZVh49Hav2dF9lBeU/MUI-for-Figma-v5.4.0---Material-UI?node-id=6543%3A42910

$defaultBackground: #f0f4f5;

$black: #000;
$darkGray: rgb(33, 43, 50);
$darkGrayTransparent: rgba(33, 43, 50, 0.7);

$primaryText: $darkGray;
$secondaryText: $darkGrayTransparent;
$secondaryTextLight: $darkGrayTransparent;
$disabledText: $darkGrayTransparent;
$primaryTextLight12p: rgba(33, 43, 50, 0.12);
$secondaryTextDark: rgba(255, 255, 255, 0.7);

$primaryMain: #007c82;
$primaryDark: #00575b;
$primaryLight: #7fbdc0;
$primaryContrastText: #fff;
$primaryContrastTextHover: rgba(255, 255, 255, 0.3);
$primaryLight8p: #e4f0f1;
$primaryLight4p: #dce1e1;
$primaryShades12p: #e0e0e0;

$secondaryMain: #921296;

$errorMain: #da291c;
$errorDark: #991d14;
$errorShades12p: #991d1420;

$warningMain: #ffb81c;
$warningDark: #996e11;
$warningShades12p: #ffb71c60;
$warningLight: rgba(255, 219, 141, 1);

$successMain: #007f3b;
$successDark: #005929;
$successLight: #e5f2eb;

$backgroundPaper: #ffffff;

$actionHover: rgba(33, 43, 50, 0.04);
$actionDisabledDark: rgba(255, 255, 255, 0.3);
$actionActiveDark: #9da2a5;
$actionActive: $darkGrayTransparent;
$actionButtonHover: rgba(0, 124, 130, 0.04);
$actionButtonHoverDark: rgba(255, 255, 255, 0.08);

$infoMain: #005eb8;
$infoLight: #e5eff8;

// Other colors
$snackbarBackground: $darkGray;
$filledInputBackground: rgba(33, 43, 50, 0.06);
$filledInputBackgroundLight: #f2f2f3;
$backdropOverlay: rgba(33, 43, 50, 0.5);
$divider: rgba(33, 43, 50, 0.25);
$lightDivider: rgba(0, 0, 0, 0.12);
$outlinedBorder: rgba(33, 43, 50, 0.23);
$standardInputLine: $darkGrayTransparent;
$alphaBannerIcon: #005eb8;
$secondaryShades4p: #eaeaeb;
$navigationOutline: #005eb8;

// Charts
$areaBackground: #f2f3f3;
$gridline: #abaeb1;
$border: #c7cacc;

//Slider
$sliderRail: rgba(255, 255, 255, 0.3);
$sliderTrack: #ffffff;
$sliderMark: rgba(255, 255, 255, 0.8);
$sliderMarkActive: rgba(0, 94, 184, 0.8);

//Linear progress bar
$progressBarPrimary: #cccccc;

:export {
  defaultBackground: $defaultBackground;
  darkGray: $darkGray;
  darkGrayTransparent: $darkGrayTransparent;
  primaryText: $primaryText;
  secondaryText: $secondaryText;
  disabledText: $disabledText;
  primaryMain: $primaryMain;
  primaryLight: $primaryLight;
  primaryDark: $primaryDark;
  primaryContrastText: $primaryContrastText;
  primaryContrastTextHover: $primaryContrastTextHover;
  secondaryMain: $secondaryMain;
  warningMain: $warningMain;
  warningDark: $warningDark;
  warningShades12p: $warningShades12p;
  errorMain: $errorMain;
  errorDark: $errorDark;
  errorShades12p: $errorShades12p;
  successMain: $successMain;
  successDark: $successDark;
  backgroundPaper: $backgroundPaper;
  actionHover: $actionHover;
  actionActive: $actionActive;
  actionButtonHover: $actionButtonHover;
  actionButtonHoverDark: $actionButtonHoverDark;
  snackbarBackground: $snackbarBackground;
  alphaBannerIcon: $alphaBannerIcon;
  filledInputBackground: $filledInputBackground;
  backdropOverlay: $backdropOverlay;
  divider: $divider;
  outlinedBorder: $outlinedBorder;
  standardInputLine: $standardInputLine;
  infoMain: $infoMain;
  primaryLight4p: $primaryLight4p;
  primaryLight8p: $primaryLight8p;
  primaryTextLight12p: $primaryTextLight12p;
  secondaryTextLight: $secondaryTextLight;
  primaryShades12p: $primaryShades12p;
  actionDisabledDark: $actionDisabledDark;
  secondaryTextDark: $secondaryTextDark;
  areaBackground: $areaBackground;
  lightDivider: $lightDivider;
  gridline: $gridline;
  border: $border;
  successLight: $successLight;
  infoLight: $infoLight;
  secondaryShades4p: $secondaryShades4p;
  black: $black;
  sliderRail: $sliderRail;
  sliderTrack: $sliderTrack;
  sliderMark: $sliderMark;
  sliderMarkActive: $sliderMarkActive;
  actionActiveDark: $actionActiveDark;
  progressBarPrimary: $progressBarPrimary;
}
