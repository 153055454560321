@use 'styles/palette.module.scss';

.aws__icon {
  position: relative;
  -webkit-box-pack: start;
  -webkit-box-align: center;
  text-decoration: none;
  box-sizing: border-box;
  text-align: left;
  display: block;
}

.aws__icon circle {
  fill: currentColor;
}
.aws__icon path {
  stroke: #ffffff;
  stroke-width: 10px;
}
